// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /*border: 2px solid #e10808;*/
    background: #F2F2F7;
    overflow: hidden
}

/*#root {*/
/*    height: 100%;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mCAAmC;IACnC,kCAAkC;IAClC,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,6BAA6B;IAC7B,mBAAmB;IACnB;AACJ;;AAEA,UAAU;AACV,oBAAoB;AACpB,IAAI","sourcesContent":["body {\n    font-family: Roboto, sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    /*border: 2px solid #e10808;*/\n    background: #F2F2F7;\n    overflow: hidden\n}\n\n/*#root {*/\n/*    height: 100%;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
