// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root-div {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

text {
    -webkit-user-select: none;
            user-select: none;
}

.bg-color-container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    transition: opacity 0.5s;
    z-index: 1;
}

.bg-color-container.top {
    z-index: 3;
}

button {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

button:active {
    transition: .1s ease-out transform;

    &:active {
        transform: scale(.98);
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,8BAA8B;IAC9B,wBAAwB;IACxB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;;IAElC;QACI,qBAAqB;IACzB;AACJ","sourcesContent":["#root-div {\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    -webkit-tap-highlight-color: transparent;\n}\n\ntext {\n    user-select: none;\n}\n\n.bg-color-container {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    background: rgba(0, 0, 0, 0.6);\n    transition: opacity 0.5s;\n    z-index: 1;\n}\n\n.bg-color-container.top {\n    z-index: 3;\n}\n\nbutton {\n    cursor: pointer;\n    user-select: none;\n}\n\nbutton:active {\n    transition: .1s ease-out transform;\n\n    &:active {\n        transform: scale(.98);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
