// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pull-down-menu-bottom-container {
    width: 100%;
    /*height: 23rem;*/
    height: 40vh;
    position: relative;
    display: flex;
    justify-content: center;

    .pull-down-menu-bottom-stick{
        position: absolute;
        width: 4.7rem;
        height: 1.5rem;
        bottom: 0.25rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PullDownMenu/Bottom.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;;IAEvB;QACI,kBAAkB;QAClB,aAAa;QACb,cAAc;QACd,eAAe;IACnB;AACJ","sourcesContent":[".pull-down-menu-bottom-container {\n    width: 100%;\n    /*height: 23rem;*/\n    height: 40vh;\n    position: relative;\n    display: flex;\n    justify-content: center;\n\n    .pull-down-menu-bottom-stick{\n        position: absolute;\n        width: 4.7rem;\n        height: 1.5rem;\n        bottom: 0.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
