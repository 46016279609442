// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
    /*height: 41.3rem;*/
    /*height: 85%;*/
    height: 80%;
    width: 100%;
    border-radius: 0 0 1.25rem 1.25rem;
    background: #FFF;
    box-shadow: 0 5px 4px 0 rgba(62, 58, 58, 0.10);
    margin-bottom: 1.25rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    /*justify-content: center;*/
}`, "",{"version":3,"sources":["webpack://./src/components/Content/Content.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,WAAW;IACX,kCAAkC;IAClC,gBAAgB;IAChB,8CAA8C;IAC9C,sBAAsB;IACtB,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,2BAA2B;AAC/B","sourcesContent":[".content-container {\n    /*height: 41.3rem;*/\n    /*height: 85%;*/\n    height: 80%;\n    width: 100%;\n    border-radius: 0 0 1.25rem 1.25rem;\n    background: #FFF;\n    box-shadow: 0 5px 4px 0 rgba(62, 58, 58, 0.10);\n    margin-bottom: 1.25rem;\n    padding-bottom: 3rem;\n    display: flex;\n    flex-direction: column-reverse;\n    align-items: center;\n    /*justify-content: center;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
