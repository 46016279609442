// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/PullDownMenu/stick.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stick {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: .1s ease-out transform;

    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }

    &:active {
        transform: scale(.98);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PullDownMenu/Stick.css"],"names":[],"mappings":"AAAA;IACI,mDAAsD;IACtD,wBAAwB;IACxB,2BAA2B;IAC3B,4BAA4B;IAC5B,kCAAkC;;IAElC;QACI,YAAY;IAChB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".stick {\n    background: url('../../images/PullDownMenu/stick.svg');\n    background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat;\n    transition: .1s ease-out transform;\n\n    &:hover {\n        cursor: grab;\n    }\n\n    &:active {\n        cursor: grabbing;\n    }\n\n    &:active {\n        transform: scale(.98);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
