// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/PullDownMenu/clouds.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pull-down-menu-container {
    position: absolute;
    overflow: hidden;
    width: 95%;
    /*height: 140vh;*/
    border-radius: 0 0 2.25rem 2.25rem;
    background: #5AC8FB;
    z-index: 2;
    top: -132vh;
    display: flex;
    flex-direction: column;
}

.clouds {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    -webkit-user-select: none;
            user-select: none;
    position: absolute;
}

#cloud-4 {
    width: 16rem;
    height: 12.5rem;
    bottom: 2rem;
    right: -1rem;
}

#cloud-3 {
    width: 14rem;
    height: 10.5rem;
    top: -0.5rem;
    left: -2rem;
    transform: scaleX(-1);
}

#cloud-2 {
    width: 12rem;
    height: 10rem;
    bottom: 5rem;
    right: -3rem;
    transform: scaleX(-1);
}

#cloud-1 {
    width: 11.75rem;
    height: 10rem;
    bottom: -2.64rem;
    left: -0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/PullDownMenu/PullDownMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,kCAAkC;IAClC,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mDAAuD;IACvD,wBAAwB;IACxB,+BAA+B;IAC/B,4BAA4B;IAC5B,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".pull-down-menu-container {\n    position: absolute;\n    overflow: hidden;\n    width: 95%;\n    /*height: 140vh;*/\n    border-radius: 0 0 2.25rem 2.25rem;\n    background: #5AC8FB;\n    z-index: 2;\n    top: -132vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.clouds {\n    background: url('../../images/PullDownMenu/clouds.svg');\n    background-size: contain;\n    background-position: center top;\n    background-repeat: no-repeat;\n    user-select: none;\n    position: absolute;\n}\n\n#cloud-4 {\n    width: 16rem;\n    height: 12.5rem;\n    bottom: 2rem;\n    right: -1rem;\n}\n\n#cloud-3 {\n    width: 14rem;\n    height: 10.5rem;\n    top: -0.5rem;\n    left: -2rem;\n    transform: scaleX(-1);\n}\n\n#cloud-2 {\n    width: 12rem;\n    height: 10rem;\n    bottom: 5rem;\n    right: -3rem;\n    transform: scaleX(-1);\n}\n\n#cloud-1 {\n    width: 11.75rem;\n    height: 10rem;\n    bottom: -2.64rem;\n    left: -0.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
