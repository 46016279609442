import "./NavigationBar.css";

import Buttons from './Buttons';

function NavigationBar() {
    return(
        <div className="navigation-bar-container">
            <Buttons/>
        </div>
    )
}

export default NavigationBar;