// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-buttons text {
    color: #000;
    font-size: 0.62rem;
    font-style: normal;
    font-weight: 400;
    line-height: 117.287%;
    letter-spacing: 0.0375rem;
}

.menu-buttons{
    height: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*padding-left: 1.75rem;*/
    /*padding-right: 1.75rem;*/
    padding-left: 6%;
    padding-right: 6%;
    -webkit-user-select: none;
            user-select: none;

    button{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /*width: 2.6rem;*/
        /*height: 2.9rem;*/
        width: 20%;
        height: 100%;
        border: 0;
        cursor: pointer;
        background: 0;

        div{
            padding-bottom: 0.95rem;
            /*width: 1.4rem;*/
            /*height: 1.4rem;*/
            width: 30%;
            height: 30%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        text {
            font-size: 0.75rem;
        }
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/NavigationBar/Buttons.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;;IAEjB;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,sBAAsB;QACtB,iBAAiB;QACjB,kBAAkB;QAClB,UAAU;QACV,YAAY;QACZ,SAAS;QACT,eAAe;QACf,aAAa;;QAEb;YACI,uBAAuB;YACvB,iBAAiB;YACjB,kBAAkB;YAClB,UAAU;YACV,WAAW;YACX,wBAAwB;YACxB,2BAA2B;YAC3B,4BAA4B;QAChC;;QAEA;YACI,kBAAkB;QACtB;IACJ;AACJ","sourcesContent":[".menu-buttons text {\n    color: #000;\n    font-size: 0.62rem;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 117.287%;\n    letter-spacing: 0.0375rem;\n}\n\n.menu-buttons{\n    height: 50%;\n    flex-grow: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    /*padding-left: 1.75rem;*/\n    /*padding-right: 1.75rem;*/\n    padding-left: 6%;\n    padding-right: 6%;\n    user-select: none;\n\n    button{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        /*width: 2.6rem;*/\n        /*height: 2.9rem;*/\n        width: 20%;\n        height: 100%;\n        border: 0;\n        cursor: pointer;\n        background: 0;\n\n        div{\n            padding-bottom: 0.95rem;\n            /*width: 1.4rem;*/\n            /*height: 1.4rem;*/\n            width: 30%;\n            height: 30%;\n            background-size: contain;\n            background-position: center;\n            background-repeat: no-repeat;\n        }\n\n        text {\n            font-size: 0.75rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
