// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pull-down-menu-buttons {
    position: absolute;
    z-index: 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 0.75rem;
    /*width: 17.3rem;*/
    width: 80%;
    bottom: 30%;
}

.pull-down-menu-buttons button {
    width: 100%;
    height: 3rem;
    fill: #F2F2F7;
    border: none;
    box-shadow: 0 4px 4px 0 rgba(62, 58, 58, 0.25);
    border-radius: 0.5rem;
    color: #727272;
    font-family: inherit;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: 117.3%; /* 0.95294rem */
    letter-spacing: 0.05rem;
}

.row-pull-down-menu-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;

    button{
        width: 100%;
        height: 3rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/PullDownMenu/BottomButtons.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,0BAA0B;IAC1B,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,8CAA8C;IAC9C,qBAAqB;IACrB,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAE,eAAe;IACpC,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;;IAEZ;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".pull-down-menu-buttons {\n    position: absolute;\n    z-index: 3;\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto auto auto;\n    gap: 0.75rem;\n    /*width: 17.3rem;*/\n    width: 80%;\n    bottom: 30%;\n}\n\n.pull-down-menu-buttons button {\n    width: 100%;\n    height: 3rem;\n    fill: #F2F2F7;\n    border: none;\n    box-shadow: 0 4px 4px 0 rgba(62, 58, 58, 0.25);\n    border-radius: 0.5rem;\n    color: #727272;\n    font-family: inherit;\n    font-size: 0.9rem;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 117.3%; /* 0.95294rem */\n    letter-spacing: 0.05rem;\n}\n\n.row-pull-down-menu-buttons {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 0.75rem;\n\n    button{\n        width: 100%;\n        height: 3rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
