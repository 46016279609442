// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-container {
    /*width: 17rem;*/
    /*height: 3rem;*/
    width: 80%;
    height: 9%;
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    -webkit-user-select: none;
            user-select: none;

    input{
        width: 12.1rem;
        background: #FFF;
        border: none;
        outline: none;
        padding: 0 1.2rem 0 1.2rem;
    }

    button{
        width: 4rem;
        background: #5AC8FB;
        border: none;
    }

    text {
        color: #FFF;
        font-size: 2.062rem;
        font-style: normal;
        font-weight: 400;
        line-height: 117.287%; /* 2.4185rem */
        letter-spacing: 0.12375rem;
    }
}

.button {
    box-shadow: 0px 4px 4px 0px rgba(62, 58, 58, 0.25);
    border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Content/ActionButtons.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,UAAU;IACV,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,yBAAiB;YAAjB,iBAAiB;;IAEjB;QACI,cAAc;QACd,gBAAgB;QAChB,YAAY;QACZ,aAAa;QACb,0BAA0B;IAC9B;;IAEA;QACI,WAAW;QACX,mBAAmB;QACnB,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,mBAAmB;QACnB,kBAAkB;QAClB,gBAAgB;QAChB,qBAAqB,EAAE,cAAc;QACrC,0BAA0B;IAC9B;AACJ;;AAEA;IACI,kDAAkD;IAClD,qBAAqB;AACzB","sourcesContent":[".buttons-container {\n    /*width: 17rem;*/\n    /*height: 3rem;*/\n    width: 80%;\n    height: 9%;\n    display: flex;\n    justify-content: space-between;\n    gap: 0.8rem;\n    user-select: none;\n\n    input{\n        width: 12.1rem;\n        background: #FFF;\n        border: none;\n        outline: none;\n        padding: 0 1.2rem 0 1.2rem;\n    }\n\n    button{\n        width: 4rem;\n        background: #5AC8FB;\n        border: none;\n    }\n\n    text {\n        color: #FFF;\n        font-size: 2.062rem;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 117.287%; /* 2.4185rem */\n        letter-spacing: 0.12375rem;\n    }\n}\n\n.button {\n    box-shadow: 0px 4px 4px 0px rgba(62, 58, 58, 0.25);\n    border-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
